import {
  CardContent, Grow, ListItem, Paper, Typography,
} from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../styles';

export default function Project({
  projectTitle,
  projectId,
  featuredImage,
}) {
  const classes = useStyles();

  return (
    <Grow in timeout={375}>
      <div>
        <Link
          to={`/project?projectId=${projectId}`}
          className={classes.link}
        >
          <Paper square style={{ margin: '0.025em' }}>
            <ListItem
              button
              disableGutters
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <CardContent style={{
                width: '100%',
                height: '100%',
              }}
              >
                {featuredImage && (
                  <img
                    style={{ width: '100%' }}
                    src={`${featuredImage}`}
                    alt={projectTitle}
                    loading="lazy"
                  />
                )}
                {projectTitle && (
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  style={{ paddingTop: '17px' }}
                >
                  {projectTitle}
                </Typography>
                )}
              </CardContent>
            </ListItem>
          </Paper>
        </Link>
      </div>
    </Grow>
  );
}

export const ProjectShape = {
  projectTitle: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  featuredImage: PropTypes.object.isRequired,
};

Project.propTypes = ProjectShape;
