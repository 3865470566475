/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { projectFirestore } from './firebase';

const getProjects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (projectFirestore) {
      const unsub = onSnapshot(collection(projectFirestore, 'projects'), (snapshot) => {
        const documents = [];
        snapshot.forEach((doc) => {
          documents.push({ ...doc.data(), projectId: doc.id });
        });
        setProjects(documents.sort(
          (first, second) => first.projectStartDate.localeCompare(second.projectStartDate) * -1,
        ));
      });
      return () => unsub();
    }
    return null;
  }, [projectFirestore]);

  return { projects };
};

export default getProjects;
