/* eslint-disable react/prop-types */
import React, {
  useState,
  createContext,
  useContext,
} from 'react';

const MenuDrawerContext = createContext();
export function useMenuDrawer() {
  return useContext(MenuDrawerContext);
}

export function MenuDrawerProvider({ children }) {
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

  return (
    <MenuDrawerContext.Provider
      value={{
        menuDrawerOpen,
        setMenuDrawerOpen,
      }}
    >
      {children}
    </MenuDrawerContext.Provider>
  );
}
