/* eslint-disable react/prop-types */
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles, styled } from '@mui/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Link } from 'gatsby';
import DesktopMenuList from '../DesktopMenuList';

const useStyles = makeStyles((theme) => ({
  desktopMenuItem: {
    transition: 'transform 0.15s, color 0.25s ease-in-out',
    '&:hover': {
      transform: 'scale3d(1.1, 1.1, 1.1)',
      backgroundColor: 'inherit',
      color: theme.palette.secondary.main,
    },
  },
  link: {
    textDecoration: 'none',
  },
  activeLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}));

const MenuListItemTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: theme.typography.pxToRem(12),
    border: 'thin solid #f1f1f1',
    borderRadius: 0,
    marginTop: 0,
    paddingTop: 0,
    maxWidth: '100vw',
  },
}));

export default function DesktopMenuListItem({
  menuItem,
  box,
  variant,
  displayItems,
}) {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setSubMenuOpen(false);
  };

  const handleOpen = () => {
    if (menuItem.menuList) {
      setSubMenuOpen(true);
    }
  };

  const [external, setExternal] = useState();
  useEffect(() => {
    if (menuItem.href.startsWith('https://') || menuItem.href.startsWith('http://')) {
      setExternal(true);
    } else {
      setExternal(false);
    }
  }, [menuItem]);

  if (external === undefined) return <></>;
  if (external) {
    return (
      <>
        <a
          href={menuItem.href}
          className={classes.link}
          target="_blank"
          rel="noreferrer"
        >
          <ListItem
            button
            dense
            className={classes.desktopMenuItem}
            style={box ? {
              border: 'solid thin #f1f1f1',
              background: 'white',
            } : {}}
            target={menuItem.target}
          >
            {
                menuItem.icon && (
                  <ListItemIcon>
                    {menuItem.icon}
                  </ListItemIcon>
                )
              }
            <ListItemText>
              <Typography
                variant={variant || 'h6'}
                align="left"
              >
                {menuItem.text}
              </Typography>
            </ListItemText>
          </ListItem>
        </a>
      </>
    );
  } return (
    <>
      <MenuListItemTooltip
        open={subMenuOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        leaveDelay={100}
        placement={displayItems || 'right'}
        title={(
          <DesktopMenuList
            menuList={menuItem.menuList}
            justifyContent="flex-start"
            variant={variant}
          />
            )}
      >
        <Link
          to={menuItem.href}
          className={classes.link}
          activeClassName={classes.activeLink}
          partiallyActive={menuItem.partiallyActive}
          onClick={() => handleClose()}
        >
          <ListItem
            button
            dense
            className={classes.desktopMenuItem}
            style={box ? {
              border: 'solid thin #f1f1f1',
              background: 'white',
            } : {}}
            target={menuItem.target}
          >
            {
                menuItem.icon && (
                  <ListItemIcon>
                    {menuItem.icon}
                  </ListItemIcon>
                )
              }
            <ListItemText>
              <Typography
                variant={variant || 'h6'}
                align="left"
              >
                {menuItem.text}
              </Typography>
            </ListItemText>
          </ListItem>
        </Link>
      </MenuListItemTooltip>
    </>
  );
}
