import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(
  createTheme(
    {
      typography: {
        fontFamily: [
          '"Montserrat SemiBold"',
        ].join(','),
      },
      palette: {
        primary: {
          main: '#1a7b9b',
          dark: '#1a7b9b',
          light: '#1a7b9b',
        },
        secondary: {
          main: '#23c1c2',
          dark: '#23c1c2',
          light: '#23c1c2',
        },
        background: {
          main: '#000000',
        },
        text: {
          secondary: '#ffffff',
        },
      },
    },
  ),
);

export default theme;
