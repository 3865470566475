import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getFirestore } from 'firebase/firestore';
import 'firebase/compat/functions';

const config = {
  apiKey: 'AIzaSyA9l98rl1wetGt7ZyNWTCEFPyo6aVPtd9Q',
  authDomain: 'poshpadsrealty-670a2.firebaseapp.com',
  databaseURL: 'https://poshpadsrealty-670a2-default-rtdb.firebaseio.com',
  projectId: 'poshpadsrealty-670a2',
  storageBucket: 'poshpadsrealty-670a2.appspot.com',
  messagingSenderId: '272095683086',
  appId: '1:272095683086:web:0644517676f407bfe1925f',
};

let instance;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}

const projectFirestore = typeof window !== 'undefined' ? getFirestore(getFirebase()) : null;
export {
  projectFirestore,
};
