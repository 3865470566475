/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {
  Grid, Grow, IconButton,
} from '@mui/material';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function Carousel({ items }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = items.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box style={{
      position: 'relative',
    }}
    >
      <div style={{}}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={10000}
        >
          {items.map((item, index) => (
            <div key={item.key}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Grow in={activeStep === index} timeout={1000}>
                  <div>
                    {item.value}
                  </div>
                </Grow>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </div>
      { items && items.length > 1 && (
      <div style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'transparent',
      }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <Grid item style={{ width: '100%' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <Grid item>
                <IconButton
                  size="large"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="large"
                  onClick={handleNext}
                  disabled={activeStep === items.length - 1}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      )}
      { items && items.length > 1 && (
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: 'transparent',
        width: '100%',
      }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              style={{
                backgroundColor: 'transparent',
              }}
            />
          </Grid>
        </Grid>
      </div>
      )}
    </Box>
  );
}

export function GroupedCarousel({
  items,
  perPage,
}) {
  const [groupedItems, setGroupedItems] = useState([]);

  useEffect(() => {
    const groups = [];
    for (let i = 0; i < items.length; i += perPage) {
      groups.push(
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {items.slice(i, i + perPage).map((item) => (
            <Grid item xs={12 / perPage}>
              {item}
            </Grid>
          ))}
        </Grid>,
      );
    }
    setGroupedItems(groups);
  }, [items]);

  return (
    <Carousel items={groupedItems} />
  );
}
