/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import React from 'react';
import DesktopMenuListItem from '../DesktopMenuListItem';

export default function DesktopMenuList({
  menuList,
  justifyContent,
  variant,
  spacing,
  box,
  direction,
  displayComponent,
}) {
  if (!menuList) return <></>;
  return (
    <>
      <Grid
        container
        direction={direction || 'column'}
        justifyContent={justifyContent || 'center'}
        spacing={spacing || 0}
      >
        {menuList.map((menuItem) => (
          <Grid item key={menuItem.id}>
            <>
              { !menuItem.component && (
              <DesktopMenuListItem
                menuItem={menuItem}
                variant={variant}
                box={box}
                displayItems={direction === 'row' ? 'bottom-start' : 'right-start'}
              />
              )}
              { menuItem.component && displayComponent && (
                menuItem.component
              )}
            </>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
