import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

export default function EmailLink({
  color,
}) {
  return (
    <>
      <Button
        variant="text"
        startIcon={(
          <MailOutlineRoundedIcon
            style={{ color }}
            size="small"
          />
)}
        style={{ textTransform: 'none' }}
        href="mailto:poshpadsrealty@gmail.com"
        target="_blank"
      >
        <Typography
          variant="body2"
          style={{ color }}
        >
          poshpadsrealty@gmail.com
        </Typography>
      </Button>
    </>
  );
}

EmailLink.propTypes = {
  color: PropTypes.string,
};

EmailLink.defaultProps = {
  color: 'white',
};
