import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.backgroundColor,
  },
  button: {
    padding: '1.3em 2em',
    borderRadius: 0,
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  section: {
    minHeight: '65vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  blueBackground: {
    backgroundColor: theme.palette.primary.main,
  },
  whiteSmokeBackground: {
    backgroundColor: 'whitesmoke',
  },
  footer: {

  },
  justifyLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  whiteBorder: {
    borderColor: theme.palette.secondary.main,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

export default useStyles;
