/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  AppBar,
  CardContent,
  Container,
  Grid,
  Grow,
  Hidden,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '../Menu';
import theme from '../../theme';
import logo from '../../images/poshpads-logo.jpeg';
import './Wrapper.css';
import '../layout.css';
import SocialMediaLinks from '../SocialMediaLinks';
import PhoneLink from '../PhoneLink';
import EmailLink from '../EmailLink';
import { MenuDrawerProvider, useMenuDrawer } from '../../context/MenuDrawerContext';
import MenuDrawer from '../MenuDrawer';
import getProjects from '../../firebase/getProjects';

function ThemedWrapper({
  children,
  title,
  featuredImage,
  window,
}) {
  const { projects } = getProjects();
  const [menuList, setMenuList] = useState();
  useEffect(() => {
    const menu = [];
    menu.push({
      id: '1',
      text: 'HOME',
      href: '/',
    }, {
      id: '2',
      text: 'ABOUT',
      href: '/about',
    });
    menu.push({
      id: '3',
      text: 'PROJECTS',
      href: '/projects',
      menuList: projects.map((project) => ({
        id: project.projectId,
        text: project.projectTitle,
        href: `/project?projectId=${project.projectId}`,
      })),
    });
    menu.push({
      id: '7',
      text: 'NEED A REALTOR',
      href: 'https://www.vyllahome.com/victorumeh',
      target: '_blank',
    }, {
      id: '8',
      text: 'CONTACT US',
      href: '/contact',
    });
    setMenuList(menu);
  }, [projects]);

  const { placeholderImage, iconImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "poshpadsrealty-web-title-background.png" }) {
          childImageSharp {
            gatsbyImageData(
              height: 380
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        iconImage: file(relativePath: { eq: "poshpads-icon.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              height: 125
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `,
  );
  const image = getImage(placeholderImage);
  const icon = getImage(iconImage);
  const bgImage = featuredImage
    ? convertToBgImage(getImage(featuredImage))
    : convertToBgImage(image);

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const {
    setMenuDrawerOpen,
  } = useMenuDrawer();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <MenuDrawer
          menuList={menuList}
          width="375px"
        />
        <AppBar
          style={{
            backgroundColor: '#000000',
          }}
          elevation={0}
          position="fixed"
        >
          <Slide
            appear={false}
            direction="down"
            in={!trigger}
          >
            <AppBar color="secondary">
              <Container>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  align="center"
                  style={{ padding: '0.3em' }}
                >
                  <Hidden only="xs">
                    <Grid item>
                      <EmailLink />
                    </Grid>
                    <Grid item>
                      <PhoneLink />
                    </Grid>
                    <Grid item>
                      <div>
                        <SocialMediaLinks color="#ffffff" />
                      </div>
                    </Grid>
                  </Hidden>
                  <Hidden smUp>
                    <Grid item xs={12}>
                      <PhoneLink />
                    </Grid>
                  </Hidden>
                </Grid>
              </Container>
            </AppBar>
          </Slide>
          <Container style={{
            paddingTop: trigger ? '1em' : '3em',
            paddingBottom: '0.8em',
          }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Link to="/">
                  <img
                    src={logo}
                    alt="poshPads-logo"
                    style={{
                      height: '5em',
                    }}
                  />
                </Link>
              </Grid>
              <Hidden only="xs">
                <Grid item>
                  <Menu menuList={menuList} variant="subtitle1" />
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item>
                  <CardContent>
                    <IconButton onClick={() => setMenuDrawerOpen(true)}>
                      <MenuIcon color="secondary" />
                    </IconButton>
                  </CardContent>
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            width: '100vw',
          }}
        >
          <Toolbar
            style={{
              height: title ? '9em' : '9.1em',
            }}
          />
          {title && (
            <BackgroundImage
              Tag="section"
              {...bgImage}
              preserveStackingContext
            >
              <div style={{
                height: 390,
                width: '100vw',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
              >
                <Grow in timeout={1500}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    style={{ height: '100%' }}
                  >
                    <Grid item>
                      { !featuredImage && (
                      <GatsbyImage
                        image={icon}
                        alt="poshpads-logo-icon"
                        style={{ maxHeight: '125' }}
                      />
                      )}
                      <Typography
                        color="white"
                        align="center"
                        variant="h2"
                        style={{ textTransform: 'uppercase' }}
                      >
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grow>
              </div>
            </BackgroundImage>
          )}
          {children}
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: '#000000',
          padding: '2.3em',
        }}
      >
        <Typography
          align="center"
          variant="body2"
          color="textSecondary"
        >
          {`© Copyright ${new Date().getFullYear()} – All Rights Reserved – Posh Pads LLC`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function Wrapper({
  children,
  title,
  featuredImage,
}) {
  return (
    <ThemeProvider theme={theme}>
      <MenuDrawerProvider>
        <ThemedWrapper title={title} featuredImage={featuredImage}>
          {children}
        </ThemedWrapper>
      </MenuDrawerProvider>
    </ThemeProvider>
  );
}
