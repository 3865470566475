/* eslint-disable react/prop-types */
import React from 'react';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import { IconButton } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function SocialMediaLinks({
  color,
}) {
  return (
    <>
      <IconButton
        size="small"
        href="https://www.facebook.com/poshpadsrealty/"
        target="_blank"
      >
        <FacebookOutlinedIcon style={{ color: color || '#4267B2' }} />
      </IconButton>
      <IconButton
        size="small"
        href="https://www.instagram.com/poshpadsrealty/"
        target="_blank"
      >
        <InstagramIcon style={{ color: color || 'black' }} />
      </IconButton>
      <IconButton
        size="small"
        href="https://www.pinterest.com/poshpadsrealty/_saved/"
        target="_blank"
      >
        <PinterestIcon style={{ color: color || '#cb2027' }} />
      </IconButton>
      <IconButton
        size="small"
        href="https://www.youtube.com/c/PoshPadsRealty"
        target="_blank"
      >
        <YouTubeIcon style={{ color: color || '#cb2027' }} />
      </IconButton>
    </>
  );
}

SocialMediaLinks.defaultProps = {
  color: undefined,
};
