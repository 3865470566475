import React from 'react';
import {
  Button,
  CardContent, Container, Grow, Typography,
} from '@mui/material';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import useStyles from '../../styles';

export default function WhoWeAre({
  enableReadMore,
}) {
  const classes = useStyles();
  return (
    <Grow in timeout={1000}>
      <div>
        <Container
          className={classes.section}
          maxWidth="md"
          disableGutters
        >
          <br />
          <CardContent>
            <Typography variant="h1">
              WHO WE ARE
            </Typography>
          </CardContent>
          <CardContent>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              align="center"
            >
              Posh Pads offers high-quality chic renovation options for spaces
              in need of a facelift. In addition, our team of highly experienced
              construction professionals can provide project management &
              development support for new projects at every stage of the process.
              Our creative team focuses on taking each concept and adding a
              personal touch to it. The use of bright colors and modern furnishings
              are a staple for our brand as we focus on making every space feel
              exciting and welcoming. We offer support for renovations, redesigns,
              new builds, and interior decor. Let us use our expertise to bring your
              project to life. Look through our
              {' '}
              <Link to="/projects">past projects</Link>
              {' '}
              and request your
              consultation
              {' '}
              <Link to="/contact">online here.</Link>
              {' '}
            </Typography>
          </CardContent>
          {enableReadMore && (
          <CardContent>
            <Link
              to="/about"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              <Button
                variant="contained"
                color="secondary"
                className={`${classes.button}`}
              >
                <Typography variant="subtitle1">
                  Read More
                </Typography>
              </Button>
            </Link>
          </CardContent>
          )}
        </Container>
      </div>
    </Grow>
  );
}

WhoWeAre.propTypes = {
  enableReadMore: PropTypes.bool,
};

WhoWeAre.defaultProps = {
  enableReadMore: false,
};
