/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
  CardContent,
  Grid, ListItem, ListItemIcon, ListItemText, Tooltip, Typography,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { Link } from 'gatsby';
import React from 'react';

import { tooltipClasses } from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  activeLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
  hover: {
    transition: 'transform 0.15s, color 0.25s ease-in-out',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1.05)',
    },
  },
}));

const MenuListItemTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    fontSize: theme.typography.pxToRem(12),
    border: 'thin solid #f1f1f1',
    borderRadius: 0,
    marginTop: 0,
    paddingTop: 0,
    maxWidth: '100vw',
  },
}));

function MegaMenuToolTip({ menuList }) {
  const classes = useStyles();
  if (!menuList) return <></>;
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      {menuList.map((menuListItem) => (
        <Grid item key={menuListItem.id}>
          <CardContent>
            <Link
              to={menuListItem.href}
              className={classes.link}
              activeClassName={classes.activeLink}
              partiallyActive={menuListItem.partiallyActive}
            >
              <ListItem
                dense
                className={classes.desktopMenuItem}
                target={menuListItem.target}
              >
                <ListItemText>
                  <Typography
                    className={classes.hover}
                    variant="body2"
                    color="secondary"
                  >
                    {menuListItem.text}
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
            {menuListItem.menuList.map((link) => (
              <Link
                to={link.href}
                className={classes.link}
                activeClassName={classes.activeLink}
                partiallyActive={link.partiallyActive}
              >
                <ListItem
                  dense
                  className={classes.desktopMenuItem}
                  target={menuListItem.target}
                >
                  <ListItemText>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      className={classes.hover}
                    >
                      {link.text}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            ))}
          </CardContent>
        </Grid>
      ))}
    </Grid>
  );
}

export default function MegaMenu({
  menuList,
  justifyContent,
  spacing,
  box,
}) {
  const classes = useStyles();
  if (!menuList) return <></>;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent={justifyContent || 'center'}
        spacing={spacing || 0}
      >
        {menuList.map((menuItem) => (
          <Grid item key={menuItem.id}>
            <MenuListItemTooltip
              leaveDelay={100}
              title={(<MegaMenuToolTip menuList={menuItem.menuList} />)}
            >
              <Link
                to={menuItem.href}
                className={classes.link}
                activeClassName={classes.activeLink}
                partiallyActive={menuItem.partiallyActive}
              >
                <ListItem
                  button
                  dense
                  className={classes.desktopMenuItem}
                  style={box ? {
                    border: 'solid thin #f1f1f1',
                    background: 'white',
                  } : {}}
                  target={menuItem.target}
                >
                  {
            menuItem.icon && (
              <ListItemIcon>
                {menuItem.icon}
              </ListItemIcon>
            )
          }
                  <ListItemText>
                    <Typography
                      variant="body2"
                      align="left"
                    >
                      {menuItem.text}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </MenuListItemTooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
