import { Button, Typography } from '@mui/material';
import React from 'react';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

import PropTypes from 'prop-types';

export default function PhoneLink({ color }) {
  return (
    <Button
      variant="text"
      startIcon={<PhoneOutlinedIcon style={{ color }} />}
      href="tel:706-250-2778"
    >
      <Typography
        variant="body2"
        style={{ color }}
      >
        706-250-2778
      </Typography>
    </Button>
  );
}

PhoneLink.propTypes = {
  color: PropTypes.string,
};

PhoneLink.defaultProps = {
  color: 'white',
};
