/* eslint-disable react/prop-types */
import {
  Avatar,
  CardContent, Grid, Paper, Rating, Typography,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../styles';

export default function Testimonial({
  rating,
  text,
  name,
  company,
  featuredImage,
}) {
  const classes = useStyles();
  return (
    <Paper
      square
      variant="outlined"
      elevation={0}
      className={`${classes.blueBackground} ${classes.whiteBorder}`}
      style={{ height: '100%' }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        style={{ height: '100%' }}
      >
        <Grid item>
          <CardContent className={classes.justifyLeft}>
            <Rating value={rating || 0} readOnly size="small" />
          </CardContent>
          <CardContent>
            <Typography
              align="left"
              variant="body2"
              color="info"
            >
              {text}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item>
          <CardContent>
            <Grid
              container
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item>
                <Avatar
                  alt={name}
                  src={featuredImage ? featuredImage.publicURL : ''}
                  sx={{ width: 56, height: 56 }}
                />
              </Grid>
              <Grid item>
                <div>
                  <Typography
                    align="left"
                    variant="body2"
                    color="textSecondary"
                  >
                    {name}
                  </Typography>
                  <Typography
                    align="left"
                    variant="body2"
                    color="secondary"
                  >
                    {company}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Paper>
  );
}

export const TestimonialShape = {
  rating: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  company: PropTypes.string,
};

Testimonial.propTypes = TestimonialShape;
Testimonial.defaultProps = {
  company: undefined,
};
