import {
  CardContent,
  Drawer,
  IconButton,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useMenuDrawer } from '../../context/MenuDrawerContext';
import MenuList from '../MenuList';

export default function MenuDrawer({
  menuList,
  width,
}) {
  const {
    menuDrawerOpen,
    setMenuDrawerOpen,
  } = useMenuDrawer();

  return (
    <Drawer
      open={menuDrawerOpen}
      style={{ maxWidth: '100vw', width }}
      onClose={() => setMenuDrawerOpen(false)}
      transitionDuration={500}
    >
      <CardContent>
        <IconButton onClick={() => setMenuDrawerOpen(false)}>
          <ChevronLeftIcon color="secondary" />
        </IconButton>
      </CardContent>
      <CardContent style={{ maxWidth: '100vw', width }}>
        <MenuList menuList={menuList} />
      </CardContent>
    </Drawer>
  );
}

const menuListShape = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  caption: PropTypes.string,
  href: PropTypes.string,
};
menuListShape.menuList = PropTypes.arrayOf(PropTypes.shape(menuListShape));
const menuListPropTypes = PropTypes.shape(menuListShape);

MenuDrawer.propTypes = {
  menuList: PropTypes.arrayOf(menuListPropTypes).isRequired,
  width: PropTypes.string,
};

MenuDrawer.defaultProps = {
  width: '376px',
};
