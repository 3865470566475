import React, { useEffect, useState } from 'react';
import {
  Container, Grid, CardContent, Typography, Button,
  Box,
} from '@mui/material';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import useStyles from '../../styles';
import Project from '../Project';
import { ProjectShape } from '../Project/Project';
import { Carousel } from '../Carousel';

export function GroupedProjectsCarousel({ projects, groupSize }) {
  const classes = useStyles();
  const [groupedProjects, setGroupedProjects] = useState();

  useEffect(() => {
    if (projects) {
      const featuredProjects = projects.filter((project) => project.featured);
      const grouped = [];
      for (let i = 0; i < featuredProjects.length; i += groupSize) {
        const group = featuredProjects.slice(i, i + groupSize);
        grouped.push(
          {
            key: i,
            value:
  <Grid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="flex-start"
    spacing={0}
  >
    {group && group.map((project) => (
      <Grid
        item
        xs={12 / groupSize}
        key={project.id}
      >
        <Project
          projectTitle={project.projectTitle}
          slug={project.projectId}
          projectId={project.projectId}
          featuredImage={project.featuredImage}
        />
      </Grid>
    ))}
  </Grid>,
          },
        );
      }
      setGroupedProjects(grouped);
    }
  }, [projects]);

  if (!groupedProjects) return <></>;
  return (
    <div className={`${classes.section} ${classes.whiteSmokeBackground}`}>
      <Container maxWidth="xl" disableGutters>
        <Box sx={{ m: 4 }} />
        <Carousel items={groupedProjects} />
        <Box sx={{ m: 4 }} />
        <CardContent>
          <Link
            to="/projects"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <Button
              variant="contained"
              color="secondary"
              className={`${classes.button}`}
            >
              <Typography variant="subtitle1">
                View Projects
              </Typography>
            </Button>
          </Link>
        </CardContent>
      </Container>
    </div>
  );
}

GroupedProjectsCarousel.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      frontmatter: PropTypes.shape(ProjectShape),
    }),
  ).isRequired,
  groupSize: PropTypes.oneOf([1, 2, 3, 4, 6, 12]),
};

GroupedProjectsCarousel.defaultProps = {
  groupSize: 1,
};

export default function Projects({ projects, sm, xs }) {
  const classes = useStyles();

  if (!projects) return <></>;
  return (
    <div className={`${classes.section} ${classes.whiteSmokeBackground}`}>
      <Container maxWidth="xl" disableGutters>
        <Box sx={{ m: 4 }} />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
        >
          {projects && projects.map((project) => (
            <Grid
              item
              sm={sm}
              xs={xs}
              key={project.projectId}
            >
              <Project
                projectTitle={project.projectTitle}
                projectId={project.projectId}
                featuredImage={project.featuredImage}
              />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ m: 4 }} />
      </Container>
    </div>
  );
}

Projects.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      frontmatter: PropTypes.shape(ProjectShape),
    }),
  ),
  sm: PropTypes.oneOf([1, 2, 3, 4, 6, 12]),
  xs: PropTypes.oneOf([1, 2, 3, 4, 6, 12]),
};

Projects.defaultProps = {
  projects: undefined,
  sm: 4,
  xs: 12,
};
