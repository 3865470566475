import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMenuDrawer } from '../../context/MenuDrawerContext';
import commonStyles from '../../styles';

export default function MenuList({ menuList }) {
  const commonClasses = commonStyles();
  const { setMenuDrawerOpen } = useMenuDrawer();

  if (!menuList) return <></>;
  return (
    <>
      {menuList.filter((item) => !!item.text).map((menuListItem) => (
        <div key={menuListItem.id}>
          {menuListItem.menuList && (
            <Accordion
              TransitionProps={{ timeout: 500 }}
              disableGutters
              elevation={0}
              square
              style={{
                border: 'none',
                '&:not(:lastChild)': {
                  borderBottom: 0,
                },
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="secondary" fontSize="small" />}
              >
                <Typography variant="h6">{menuListItem.text}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: '0' }}>
                <div style={{ paddingLeft: '17px' }}>
                  <MenuList menuList={menuListItem.menuList} />
                </div>
              </AccordionDetails>
            </Accordion>
          )}
          {!menuListItem.menuList && (
          <Link
            to={menuListItem.href}
            onClick={() => setMenuDrawerOpen(false)}
            className={commonClasses.link}
          >
            <ListItem button>
              <ListItemText>
                <Typography variant="h6">{menuListItem.text}</Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  noWrap
                >
                  {menuListItem.caption}
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
          )}
        </div>
      ))}
    </>
  );
}

const menuListShape = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  caption: PropTypes.string,
  href: PropTypes.string,
};
menuListShape.menuList = PropTypes.arrayOf(PropTypes.shape(menuListShape));

export const menuListPropTypes = PropTypes.shape(menuListShape);

MenuList.propTypes = {
  menuList: PropTypes.arrayOf(menuListPropTypes).isRequired,
};
