import React from 'react';
import {
  Grid,
  Container,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from '../../styles';
import Testimonial from '../Testimonial';
import { TestimonialShape } from '../Testimonial/Testimonial';

export default function Testimonials({ testimonials }) {
  const classes = useStyles();

  if (!testimonials) return <></>;
  return (
    <div className={`${classes.section} ${classes.blueBackground}`}>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h2">Testimonials</Typography>
            </CardContent>
          </Grid>
          {testimonials && testimonials.slice(0, 2).map((testimonial) => (
            <Grid item sm={6} xs={12} key={testimonial.id}>
              <Testimonial
                rating={testimonial.frontmatter.rating}
                text={testimonial.frontmatter.text}
                name={testimonial.frontmatter.name}
                company={testimonial.frontmatter.company}
                featuredImage={testimonial.frontmatter.featuredImage}
              />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ m: 4 }} />
      </Container>
    </div>
  );
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.shape(TestimonialShape)),
};

Testimonials.defaultProps = {
  testimonials: undefined,
};
