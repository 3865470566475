/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import MegaMenu from '../MegaMenu';
import DesktopMenuList from '../DesktopMenuList';

const calculateMenuDepth = (menuList) => {
  if (!menuList) {
    return 0;
  }
  let maxDepth = 0;
  for (let i = 0; i < menuList.length; i += 1) {
    const depth = 1 + calculateMenuDepth(menuList[i].menuList);
    if (depth > maxDepth) {
      maxDepth = depth;
    }
  }
  return maxDepth;
};

export default function Menu({
  menuList,
  variant,
}) {
  const [menuDepth, setMenuDepth] = useState();

  useEffect(() => {
    if (menuList) {
      const depth = calculateMenuDepth(menuList);
      setMenuDepth(depth);
    }
  }, [menuList]);

  if (!menuDepth) return <></>;
  if (menuDepth === 3) {
    return <MegaMenu menuList={menuList} />;
  }
  return (
    <DesktopMenuList
      menuList={menuList}
      variant={variant || 'body2'}
      direction="row"
    />
  );
}
